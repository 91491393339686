<template>
  <div class="c-app">
    <CWrapper>
      <div class="c-body" style="height: 100%; overflow: hidden;">
        <main class="c-main" style="padding: 0px;">
          <div v-if="enrollment_id > 0">
            <CRow>
              <CCol md="12" :style='header_type'></CCol>
            </CRow>
            <CRow>
              <CCol md="2" style="text-align: left;"></CCol>
              <CCol md="8" style="text-align: left;">
                <div style="background-color: white; position: relative; top: -50px; padding: 30px; height: 360px; font-size: 16px; font-family: 'Open Sans'; ">
                  <h2>¡¡FORMULARIO ENVIADO!!</h2>
                  <br />
                  <p style="text-align: justify; text-justify: inter-word;">El formulario ha sido enviado correctamente, la información ha sido recibida por el equipo de Con Equis.</p>
                  <p style="text-align: justify; text-justify: inter-word;">Cualquier duda, les pedimos que nos contacten por celular o whatsapp al {{travel.contact_name}} con {{travel.contact_phone}}.</p>
                  <br /><br />
                  <img src="main_logo.png" style="width: 100px; float: right;">
                </div>
              </CCol>
              <CCol md="2" style="text-align: left;"></CCol>
            </CRow>
          </div>
          <CForm v-if="enrollment_id == 0" id="enrollment_form" method="POST" enctype="multipart/form-data">
            <CContainer fluid style="padding: 0px;">
              <CRow>
                <CCol md="12" :style='header_type'>

                </CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="background-color: white; position: relative; top: -50px; padding: 30px; font-size: 14px; font-family: 'Open Sans';  text-align: center;">
                    <img src="main_logo.png" style="width: 200px;">
                    <br /><br /><br />
                    <h2><b>{{travel.form_name}}</b></h2>
                    <br />
                    <pre style='width: 100%; font-size: 16px; font-family: "Open Sans"; white-space: pre-wrap; font-size: 14px; text-align: justify;'><div v-html="travel.welcome"></div></pre>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <!-- Preguntas Dinámicas -->
              <CRow v-for="question in travel.questions">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <pre v-if="question.type == 'content'" style='width: 100%; font-size: 16px; padding: 0px 30px 0 30px; font-family: "Open Sans"; white-space: pre-wrap; font-size: 14px; text-align: justify;'><div v-html="question.description"></div><br /></pre>
                  <div v-if="question.type != 'content'" style="position: relative; top: -50px; padding: 30px 30px 0 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>{{question.question}}</b> <span v-if="question.required" style="color: red">*</span>
                    <br />
                    <span style="font-size: 14px;" v-if="question.description">{{question.description}}</span>
                    <br v-if="question.description" />
                    <br v-if="question.type != 'date'" />
                    <input v-if="question.type == 'text'" v-model="question.answer" type="text" :name="'text_'+question.id" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <input v-if="question.type == 'number'" v-model="question.answer" type="number" :name="'number_'+question.id" value="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);">
                    <CInput v-if="question.type == 'file'" v-model="question.answer" type="file" id="formFile" :name="'question_'+question.id" label="" style="padding: 6px; width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);" />
                    <div style="margin-bottom: 10px;" v-if="question.type == 'options'" v-for="option in question.options">
                      <input class="form-check-input" type="radio" v-model="question.answer" :value="option" :name="'options_'+question.id" id="flexRadioDefault2" style="margin-left: 10px;">
                      <span style="background-color: #CCC; border-radius: 5px; padding: 5px; font-size: 12px; min-width: 18px; height: 18px;font-size: 13px; margin-left: 30px; margin-right: 40px;">{{option}}</span>
                    </div>
                    <span v-if="question.error" style="color: red; font-size: 12px; text-align: right;">{{question.error}}</span>
                  </div>
                  <div v-if="question.type == 'date'" style="position: relative; top: -25px; padding: 0px 30px 0 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <v-date-picker
                      class="date-picker"
                      v-model="question.answer"
                      locale="es-MX"
                      size="sm"
                      style="width: 100%; border-radius: 0.25rem; border-color: rgb(255,255,255);"
                    />
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <!-- Termina Preguntas Dinámicas -->
              <CRow v-if="false">
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <div style="position: relative; top: -50px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; ">
                    <b>Términos y condiciones</b> <span style="color: red">*</span>
                    <br /><br />
                    <div style="margin-bottom: 10px;">
                      <input class="form-check-input" type="checkbox" v-model="form.accept" name="accept" id="flexRadioDefault2" style="margin-left: 0px;">
                      <span style="font-size: 14px; margin-left: 25px;">Acepto que la información anterior es fidedigna y confiable. Los datos aquí proporcionados serán utilizados por Con Equis para la correcta realización del viaje.</span>
                      <br />
                      <span v-if="errors.terms" style="color: red; font-size: 12px; text-align: right;">{{errors.terms}}</span>
                    </div>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
              <CRow>
                <CCol md="4" style="text-align: left;"></CCol>
                <CCol md="4" style="text-align: left;">
                  <br /><br /><br />
                  <div style="position: relative; top: -80px; padding: 30px; font-size: 16px; font-family: 'Open Sans'; text-align: center;">
                    <CButton v-if="!is_loading" @click="sendData" color="info">Enviar Formulario</CButton>
                    <div v-if="is_loading"><loading :active="is_loading" :can-cancel="true"></loading>Enviando Información</div>
                  </div>
                </CCol>
                <CCol md="4" style="text-align: left;"></CCol>
              </CRow>
            </CContainer>
          </CForm>
        </main>
      </div>
    </CWrapper>
  </div>
</template>

<script>
import store from '../../store'
import travels from '../../services/travels';
import schools from '../../services/schools';
import enrollments from '../../services/enrollments';
import { DatePicker } from 'v-calendar'
import Loading from 'vue-loading-overlay/dist/vue-loading.js';

import moment from 'moment';
import moment_timezone from 'moment-timezone'

moment.tz.setDefault('America/Mexico_city')

let today = new moment();
today = new moment(today.format("YYYY-MM-DD HH:m:ss")); 
let today2 = new moment(today.format("YYYY-MM-DD HH:m:ss")).subtract(1, 'month');

export default {
  name: 'DynamicForm',
  components: { 'v-date-picker': DatePicker, Loading },
  data: function () {
		return {
      enrollment_id: 0,
      enrollment: 0,
      is_loading: false,
      code: '',
      header_type: '',
      travel: false,
      schools: [],
      form: {
        travel_id: 0,
        name: '',
        form_name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: '',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        accept: false
      },
      has_errors: false,
      first_error: false,
      errors: {
        name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: '',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        terms: ''
      }
    }
  },
  mounted: async function() {
    this.code = await this.$route.params.code;

    let response = await travels.show(this.code);

    if(response.type == "success"){
      this.travel = response.data;

      if(response.data.is_active == 0){
        window.location = "/#/login";

        return;
      }

      this.form.travel_id = this.travel.id;
      this.form.school_id = this.travel.school_id;

      this.header_type = 'background-image: url("'+this.travel.image_filename+'"); overflow: hidden; background-position: center; width: 100%; height: 240px; background-repeat: no-repeat;background-size: cover;';
    }
  },
  methods: {
    async sendData (){
      this.loading();

      if(this.validateData()){
        if(this.has_errors){
          this.loaded();
          return;
        }
      }

      const form = document.getElementById("enrollment_form");
      const formData = new FormData(form); 

      formData.append("travel_id", this.form.travel_id);
      formData.append("school_id", this.form.school_id);
      formData.append("travel_form_id", this.travel.travel_form_id);

      this.travel.questions.forEach(question => {
        if(question.type != "content"){
          if(question.type == "date"){
            let date = new moment(question.answer);
            formData.append("question_"+question.id, date.format("YYYY-MM-DD"));
          }
          else{
            formData.append("question_"+question.id, question.answer && question.answer != undefined ? question.answer : '');
          }
        }
      });

      let response = await enrollments.store(formData);

      if(response.type == "success"){
        let data = response.data;

        this.enrollment_id = data.id;
        this.enrollment = data;
      }

      this.loaded();
    },
    validateData (){
      this.has_errors = false;
      this.first_error = false;
      
      this.errors = {
        name: '',
        first_name: '',
        last_name: '',
        mother_last_name: '',
        birthdate: '',
        age: '',
        nationality: '',
        residence: '',
        has_second_nationality: "",
        second_nationality: '',
        out_passport: '',
        in_passport: '',
        passport: '',
        visa: '',
        curp: '',
        gender: '',
        height: '',
        diseases: '',
        allergies: '',
        customer_name: '',
        customer_email: '',
        customer_email2: '',
        customer_phone: '',
        emergency_phone: '',
        comments: '',
        terms: ''
      }

      let _this = this;

      //Validación de campos personalizados
      let i = 0;
      this.travel.questions.forEach(question => {
        question.error = "";

        if(question.required == 1 && question.type != 'date'){
          if(question.answer === undefined || question.answer.trim() === ''){
            question.error = "El campo "+question.question+" del alumno(a) es obligatorio."
            this.has_errors = true;
            this.first_error = this.first_error ? this.first_error : question.code;
            return;
          }
        }

        i++;
      });

      if(this.has_errors == true){
        this.goto(this.first_error);

        return false;
      }

      return true;
    },
    validateTerms (){
      if(this.form.accept == ''){
        this.errors.terms = "Para continuar es necesario que aceptes los terminos y condiciones.";
        return false;
      }

      return true;
    },
    goto(refName) {
      if(refName != ''){
        const d = document.getElementById(refName);
        const top = d.offsetTop - 50;
        
        window.scrollTo(0, top); 
      }
    },
    changeDate(event, id) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
    },
    loading(){
      this.is_loading = true;
    },
    loaded(){
      this.is_loading = false;
    }
  },
  watch: {
    'form.birthdate': {
      handler: function() {
        let date1 = new moment(this.travel.start_date); 
        let date2 = new moment(this.form.birthdate);

        let years = parseInt(date1.diff(date2, 'months') / 12);
        let months = date1.diff(date2, 'months') % 12;

        this.form.age = years+(years == 1 ? " año " : " años ")+months+(months == 1 ? " mes" : " meses");
      },
      deep: true
    }
  }
}
</script>
